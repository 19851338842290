import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import { TypeB } from "components/callouts";
import productData from "components/product-data";
import Slides from "components/product-data/slides";
import imgEphedrineSulfate from "images/products/img-prevduo-box-and-syringe.webp";
import bivalirudinBoxImg from "images/home/bivalirudin-box-and-vial.webp";
import dexmedBoxImg from "images/home/dexmed-pole-image.webp";
import ephedrineBoxImg from "images/home/ephedrine-sulfate-injection-bottle.svg";
import vasostrictBoxImg from "images/home/vasostrict-bottles.webp";
import './products.scss';

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> PREVDUO<sup>®</sup>. Prescribing Information. Endo USA, Inc. <strong>2.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed April 29, 2024. <strong>3.</strong> Data on file. DOF-PRVD-01. Endo USA, Inc.; July 20, 2023. <strong>4.</strong> Data on file. DOF-DX-01. Endo USA, Inc.; July 26, 2023. <strong>5.</strong> Data on file. DOF-EH-01. Endo USA, Inc.; July 31, 2023. <strong>6.</strong> Data on file. DOF-VS-02. Endo USA, Inc.; September 20, 2023. <strong>7.</strong> Bivalirudin Injection. Prescribing Information. Endo USA, Inc. <strong>8.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023.
        </p>
    </>
)

function PrevduoInjectionPage({ pageContext }) {

    return (
        <Layout
            product="prevduo"
            pageClass="products page-prevduo"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="PREVDUO® (neostigmine methylsulfate and glycopyrrolate) Injection" pageDescription="PREVDUO® (neostigmine methylsulfate and glycopyrrolate) Injection available in prefilled syringes" />

            <section className="first">
                <div className="centered-content">
                    <ProductHero
                        productTitle="PREVDUO<sup>®</sup> <br class='show-desktop' /> (neostigmine methylsulfate and glycopyrrolate) Injection"
                        productSubTitle="Available in prefilled syringes"
                        productUnitsOne="Neostigmine methylsulfate: <br class='show-mobile' />3 mg/3 mL (1 mg/mL)"
                        productUnitsTwo="Glycopyrrolate: <br class='show-mobile' />0.6 mg/3 mL (0.2 mg/mL)"
                        productImg={imgEphedrineSulfate}
                        productImgAlt="Prevduo carton and prefilled syringe"
                        productImgHeight='2553'
                        productImgWidth='1909'
                        className="prd-prevduo"
                    ></ProductHero>

                    <div className="hero-inner__footnote">
                        <p>PREVDUO<sup>®</sup> is a registered trademark of Slayback Pharma LLC.</p>
                        <p>PREVDUO<sup>®</sup> is an alternative to your compounded neostigmine methylsulfate and glycopyrrolate prefilled syringes and/or any 503B or FDA-approved neostigmine methylsulfate or glycopyrrolate prefilled syringes and is not intended to replace other reversal neuromuscular blocking agents (NMBAs). Please refer to the Prescribing Information for labeled indication.</p>
                    </div>
                </div>
            </section>

            <section className="gray-bg">
                <div className="centered-content">
                    <Row align="start" justify="between">
                        <Col xl={4.75} lg={5} xs={12}>
                            <SlideContent
                                subTitle="PREVDUO<sup>®</sup> Product Details"
                                details="PREVDUO<sup>®</sup> prefilled syringes do not require compounding, diluting, mixing, or transferring and provide extended dating compared with compounded products.<sup>1-3</sup>* PREVDUO<sup>®</sup> prefilled syringes are preservative-free, latex-free, and compatible with most automated dispensing machines."
                            />
                        </Col>
                        <Col lg={6} xs={12}>
                            <Slides
                                data={productData.prevduo}
                                tableFootnote={`M&D: Morris & Dickson; NDC: National Drug Code.`}
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="links-row">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <a
                                href="https://www.prevduo.com/workflow-scenarios"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="external-link"
                                style={{ display: 'inline-block' }}>
                                Pharmacy Workflow Preparation
                            </a>

                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/37a9f0ce-da6d-4933-af6f-f6e8bb1cbc30/37a9f0ce-da6d-4933-af6f-f6e8bb1cbc30_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    PREVDUO<sup>®</sup> Information Document
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/f13b348c-95ec-49d0-a731-ebeefdbf6a47/f13b348c-95ec-49d0-a731-ebeefdbf6a47_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    PREVDUO<sup>®</sup> Market Research Infographic
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/b605e411-78a5-4771-8e51-677f142a9203/b605e411-78a5-4771-8e51-677f142a9203_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    PREVDUO<sup>®</sup> Dimensions Guide
                                </a>
                            </p>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="cta-row">
                <div className="centered-content">
                    <div className="block-title">
                        <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
                    </div>

                    <Row justify="center">
                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Bivalirudin Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={bivalirudinBoxImg}
                                ctaImageHeight='1582'
								ctaImageWidth='2009'
                                ctaImageAlt='Bivalirudin injection ready-to-use bottles'
                                pdfLink='https://endodocuments.com/BIVALIRUDIN/PI'
                                buttonText="View Product"
                                buttonURL="/products/bivalirudin-injection"
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                className='dexmed-img'
                                ctaTitle="Dexmedetomidine Hydrochloride Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={dexmedBoxImg}
                                ctaImageHeight='551'
								ctaImageWidth='324'
                                ctaImageAlt='Dexmedetomidine Hydrochloride injection ready-to-use bottles'
                                pdfLink='https://endodocuments.com/dexmedetomidine/PI'
                                buttonText="View Product"
                                buttonURL="/products/dexmedetomidine-hcl"
                                captionClass='img-caption__right'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Ephedrine Sulfate<br class='show-desktop' /> Injection"
                                ctaSubTitle="Premixed vials"
                                ctaImage={ephedrineBoxImg}
                                ctaImageHeight='160'
								ctaImageWidth='194'
                                ctaImageAlt='Ephedrine sulfate injection premixed vials'
                                pdfLink='https://www.endodocuments.com/EPHEDRINE_5/PI'
                                buttonText="View Product"
                                buttonURL="/products/ephedrine-sulfate-injection"
                                captionClass='img-caption__right img-caption__rightAlt'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Vasostrict<sup>&reg;</sup><br />(vasopressin injection, USP)"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={vasostrictBoxImg}
                                ctaImageHeight='618'
								ctaImageWidth='610'
                                ctaImageAlt='Vasostrict ready-to-use bottles'
                                pdfLink='https://endodocuments.com/vasostrict/pi'
                                buttonText="View Product"
                                buttonURL="/products/vasostrict-injection"
                                captionClass='img-caption__right'
                                className='vaso-img pb-lg-0'
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 compounded sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>2</sup> versus shelf life of 2 years at CRT for Dexmedetomidine, Ephedrine, and PREVDUO<sup>®</sup><sup>3-5</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®6</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>7,8</sup></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default PrevduoInjectionPage;